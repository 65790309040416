@font-face {
    font-family: 'InterRegular';
    src: url('/public/fonts/Inter-Regular.ttf') format('truetype');
}
@font-face {
    font-family: 'InterBold';
    src: url('/public/fonts/Inter-Bold.ttf') format('truetype');
}

html {
    scroll-behavior: smooth;
}
body {
    margin: 0px !important;
}
* {
    font-family: InterRegular,sans-serif !important;
}

.swiper-pagination-bullet {
    background-color: white !important;
}
.swiper-pagination-bullet-active {
    background-color: #F27405 !important;
}
